import { createDependencyTreeModel } from '@gmini/common/lib/classifier-editor/DependencyTree/model/treeModel'

import { currentUserClassifier$ } from '../../CurrentUserClassifier/CurrentUserClassifier'

import { classifierService } from '../../../services/userClassifierService'
//TODO coздать core как в других приложениях
export const dependencyTreeModel = createDependencyTreeModel({
  currentEntity$: currentUserClassifier$,
  nodes$: classifierService.nodes$,
})
