import { merge, restore, sample } from 'effector'
import * as smApi from '@gmini/sm-api-sdk'

import * as gStationApi from '@gmini/sm-api-sdk/lib/GStationApi/DocumentManagement'

import { currentAssembly$ } from '../../CurrentAssembly/CurrentAssembly'

const dependenciesWithModels = merge([
  smApi.DependencyWithModels.getClassifierDependencyModels.doneData,
  smApi.DependencyWithModels.getAssemblyDependencyModels.doneData,
])

sample({
  clock: dependenciesWithModels,
  source: currentAssembly$,
  fn: (currentAssembly, { dependencyModels }) => {
    if (!currentAssembly) {
      throw new Error('Current assembly is not defined')
    }
    const req: gStationApi.DocumentManagement.GetDerivativeReq['files'] = []
    dependencyModels.forEach(({ models }) => {
      models.forEach(m => {
        m.viewerRefs?.forEach(ref => {
          req.push({ urn: ref.urn, version: ref.version })
        })
      })
    })
    return { req, projectUrn: currentAssembly.projectUrn }
  },
}).watch(data => {
  if (data?.req.length) {
    gStationApi.DocumentManagement.fetchModelDerivative.defaultContext.submit({
      files: data.req,
      projectId: data.projectUrn,
    })
  }
})

export const viewerDerivatives$ = restore(
  gStationApi.DocumentManagement.fetchModelDerivative.defaultContext.doneData,
  null,
)
