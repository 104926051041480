import { combine } from 'effector'

import { isNotEmpty } from '@gmini/utils'

import { getNode } from '@gmini/common/lib/classifier-service'

import {
  isReferenceNode,
  isUserClassifierGroupNode,
  isBimNode,
} from '@gmini/common/lib/classifier-service/Node'

import { getParentsByPath } from '@gmini/common/lib/classifier-editor/ClassifierTree/utils'

import { isApiFlatNode } from '@gmini/common/lib/classifier-editor'
import { createCheckedModel } from '@gmini/common/lib/classifier-editor/CheckedModel'

import { classifierService } from '../../../services/userClassifierService'

import { dependencyTreeModel } from './dependencyTreeModel'

export const assemblyCheckedModel = createCheckedModel()
export const dependencyCheckedModel = createCheckedModel()

export const dependencyCheckedItems$ = combine(
  {
    flatTree: dependencyTreeModel.flatTree$,
    checked: dependencyCheckedModel.checked$,
  },
  ({ checked, flatTree }) =>
    flatTree
      .filter(({ path }) => checked[path.join(':')])
      .filter(isApiFlatNode)
      .map(({ ref, path }) => {
        const node = getNode(classifierService.nodes$.getState(), ref)
        const reference = getParentsByPath(
          classifierService.nodes$.getState(),
          path,
        ).find(isReferenceNode)
        if (node && isBimNode(node) && reference) {
          return {
            id: node.id,
            type: node.type,
            parentClassifierId: reference.parentClassifierId,
            parentClassifierVersion: reference.parentClassifierVersion,
            sourceParentGroupId: reference.parentGroupId,
          }
        } else if (
          node &&
          (isReferenceNode(node) || isUserClassifierGroupNode(node))
        ) {
          return {
            id: node.id,
            type: node.type,
            parentClassifierId: node.parentClassifierId,
            parentClassifierVersion: node.parentClassifierVersion,
            sourceParentGroupId: node.parentGroupId,
          }
        }
        return null
      })
      .filter(isNotEmpty),
)
