import React from 'react'

import { useStore } from 'effector-react'

import { Store } from 'effector'

import { useSnackbar } from 'notistack'

import { getNode, Nodes } from '@gmini/common/lib/classifier-service'
import {
  BimRef,
  isBimNode,
  isReferenceNode,
  ReferenceNode,
  AssemblyClassifierNode,
  BaseClassifierNode,
} from '@gmini/common/lib/classifier-service/Node'

import { NodeLayout } from '@gmini/common/lib/classifier-editor/DependencyTree/NodeLayout'

import { DependencyTreeModel } from '@gmini/common/lib/classifier-editor/DependencyTree/model/treeModel'

import { ExpandModel } from '@gmini/common/lib/classifier-editor/DependencyTree/model/expandModel'

import { pendingMapClassifier$ } from '@gmini/common/lib/classifier-editor/ClassifierTree/model/pendingModel'

import { getViewerRefs } from '@gmini/common/lib/classifier-editor/Common'

import {
  DependencyTree,
  SearchModel,
  SearchSourceData,
} from '@gmini/common/lib/classifier-editor'

import {
  TreeLoader,
  operationsPending$,
} from '@gmini/common/lib/classifier-editor/TreeLoader'

import { CheckedModel } from '@gmini/common/lib/classifier-editor/CheckedModel'

import * as smApi from '@gmini/sm-api-sdk'
import { NumCircle, NumCircleWrap } from '@gmini/common/lib/components'
import { FilterPanelService } from '@gmini/common/lib/components/FilterPanel/model/filterPanelService.types'
import { SelectedSourceNodesStore } from '@gmini/common/lib/components/FilterPanel/components'

type DependencyTreeWrapProps = {
  searchModel: SearchModel
  dependencyExpandModel: ExpandModel
  currentAssembly: AssemblyClassifierNode | null
  nodes$: Store<Nodes>
  dependencyTreeModel: DependencyTreeModel
  dependencyCheckedModel: CheckedModel
  disableOperations: boolean
  currentDependencies: BaseClassifierNode[]
  searchSourceData$: Store<SearchSourceData | null>
  selectForgeRefs: (value: Record<string, string[]>) => void
  sources: FilterPanelService['sources']
  selectedSourceNodes$: SelectedSourceNodesStore
  openedFilterPanel$: FilterPanelService['openedFilterPanel$']
}

export const DependencyTreeWrap = ({
  searchModel,
  dependencyExpandModel,
  dependencyTreeModel,
  currentAssembly,
  nodes$,
  dependencyCheckedModel,
  disableOperations,
  currentDependencies,
  searchSourceData$,
  selectForgeRefs,
  openedFilterPanel$,
  selectedSourceNodes$,
  sources,
}: DependencyTreeWrapProps) => {
  const { searchMatched$ } = searchModel
  const nodes = useStore(nodes$)
  const pendingMap = useStore(pendingMapClassifier$)

  const operationsPending = useStore(operationsPending$)

  const findAnywhere = React.useCallback(
    async (
      ref:
        | BimRef
        | Pick<ReferenceNode, 'id' | 'type'>
        | Pick<AssemblyClassifierNode, 'id' | 'type'>,
      path: string[],
    ) => {
      let node = getNode(nodes, ref)
      if (node && isReferenceNode(node)) {
        node = getNode(nodes, node.element)
      }

      if (node?.type === 'AssemblyClassifierNode' && node.assemblyModelRef) {
        node = getNode(nodes, node.assemblyModelRef.element)
      }

      if (node && isBimNode(node)) {
        searchModel.setSearchNodes({ type: 'search', nodes: [{ node, path }] })

        const viewerRefs = await getViewerRefs(node, nodes)

        if (viewerRefs) {
          selectForgeRefs(viewerRefs)
        }
      }
    },
    [nodes, searchModel, selectForgeRefs],
  )

  const onPending = React.useCallback((key: string) => !!pendingMap[key], [
    pendingMap,
  ])

  const { enqueueSnackbar } = useSnackbar()

  const notify = React.useCallback(
    (reason: string) => {
      enqueueSnackbar(reason, {
        variant: 'error',
      })
    },
    [enqueueSnackbar],
  )
  React.useEffect(() => {
    if (currentAssembly?.id && currentAssembly?.version) {
      smApi.getSourceClassifiersVersions.submit({
        id: currentAssembly.id,
        version: currentAssembly.version,
      })
    }
  }, [currentAssembly?.id, currentAssembly?.version])

  const removeRef = React.useCallback(
    (params: {
      readonly id: number
      readonly version: number
      readonly sourceClassifierId: number
    }) => {
      smApi.AssemblyClassifier.removeDependency.defaultContext.submit(params)
    },
    [],
  )

  if (!currentAssembly) {
    return null
  }

  return (
    <>
      <TreeLoader />
      <DependencyTree
        dynamicGroupsConditions={{}}
        notify={notify}
        nodes$={nodes$}
        currentEntity={currentAssembly}
        treeModel={dependencyTreeModel}
        checkedModel={dependencyCheckedModel}
        expandModel={dependencyExpandModel}
        selectedFromOtherTreeCount={0}
        onFindAnywhere={findAnywhere}
        onDelete={removeRef}
        searchMatched$={searchMatched$}
        onPending={onPending}
        selectedPath={{ path: [] }}
        hideCtxMenu={operationsPending}
        openedFilterPanel$={openedFilterPanel$}
        selectedSourceNodes$={selectedSourceNodes$}
        sources={sources}
        renderNodeLayout={({ node, nodeLayoutProps, path }) => {
          const nextProps = {
            ...nodeLayoutProps,
          }

          if (
            currentDependencies &&
            node.type === 'ReferenceNode' &&
            node.element.type === 'BimModelNode'
          ) {
            const depIdx = currentDependencies.findIndex(
              ({ id }) => id === node.parentClassifierId,
            )

            if (depIdx + 1 > 0) {
              nextProps.numCycle = (
                <>
                  <NumCircleWrap>
                    <NumCircle tooltipTitle={node.element.name}>
                      {depIdx + 1}
                    </NumCircle>
                  </NumCircleWrap>
                </>
              )
            }
          }

          return <NodeLayout {...nextProps} />
        }}
        disableOperations={disableOperations}
        searchSourceData$={searchSourceData$}
      />
    </>
  )
}
