import { SeoEventManager } from '@gmini/common'
import Keycloak from 'keycloak-js'

export const wsUrl = getEnv('SPA_WS_URL')
export const sentryUrl = getEnv('SPA_SET_MANAGEMENT_SENTRY_DSN')
export const envType = getEnv('SPA_ENV_TYPE')
export const keycloakUrl = getEnv('SPA_KEYCLOAK_SERVER_URL')
export const clientId = getEnv('SPA_KEYCLOAK_CLIENT_ID')
export const realm = getEnv('SPA_KEYCLOAK_REALM')
export const seoEnabled = getEnv('SPA_SEO') === 'true'
export const envName = getEnv('SPA_AUTH_RESOURCE_ENV')
export const envLinks = getEnv('SPA_LINKS_CONFIG')
export const gStationDocumentManagementUrl = getEnv(
  'SPA_G_STATION_DOCUMENT_MANAGEMENT_SERVER_URL',
)
export const isGIPRO = parseBoolEnv(getEnv('SPA_IS_GIPRO'))

export const seoEventManager = new SeoEventManager({ envName, seoEnabled })

const {
  SPA_LOG_EFFECTOR,
}: {
  readonly SPA_LOG_EFFECTOR: undefined | string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} = globalThis as any

export const logEffector = !!SPA_LOG_EFFECTOR && SPA_LOG_EFFECTOR !== 'false'

export const MAX_NESTING_LEVEL = 6

export const DEFAULT_FETCH_LIMIT = 40

function getEnv(name: string): string {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const value: null | undefined | string = (globalThis as any)[name]

  if (value == null) {
    console.warn(`\`${name}\` environment variable is not defined`)
    return ''
  }

  return value
}

function parseBoolEnv(value: string | null): boolean | null {
  switch (value) {
    case 'true':
      return true
    case 'false':
      return false
    default:
      return null
  }
}

export const keycloakClient = Keycloak({
  clientId,
  realm,
  url: keycloakUrl,
})
