import { ApiErrMessageMapType } from '@gmini/common/lib/classifier-service/ApiMessageMapType'

import { MAX_NESTING_LEVEL } from '../config'

export const apiErrMessageMap: ApiErrMessageMapType = {
  NOT_UNIQUE_NAME: 'Имя уже используется, пожалуйста, задайте другое',
  MAX_NESTING_LEVEL: `Максимальный уровень вложенности ${MAX_NESTING_LEVEL}`,
  CIRCULAR_DEPENDENCIES_NOT_SUPPORTED:
    'Нельзя создавать циклические зависимости',
  ERROR_REMOVE_DEPENDENCY_HAS_REFERENCES:
    'Невозможно удалить зависимость, сначала удалите разложенные элементы',
  ERROR_DELETE_CLASSIFIER_HAS_DEPENDENTS:
    'Невозможно удалить классификатор, он добавлен в зависимость',
  CLASSIFIER_VERSION_NOT_LAST_ONE:
    'Версия классификатора устарела. Пожалуйста, выберите последнюю версию',
  ALREADY_BEEN_ADDED:
    'Добавление невозможно. Папка уже содержит этот элемент модели',
  PARENT_OR_CHILD_ALREADY_BEEN_ADDED:
    'Добавление невозможно. Папка уже содержит родительский или дочерний элемент модели',
  GROUP_CONTENT_ERROR:
    'Добавление невозможно. Папка не может содержать одновременно папки и элементы модели',
  INDIRECT_MODEL_ELEMENT_REF_CANNOT_BE_MODIFIED:
    'Добавление невозможно. Нельзя разбивать перенесенную структуру',
  DEPENDENCIES_ALREADY_HAS_MODEL_DIFFERENT_VERSION:
    'В зависимостях классификатора уже содержится модель другой версии',
  DEPENDENCIES_ALREADY_HAS_CLASSIFIER_DIFFERENT_VERSION:
    'В зависимостях классификатора уже содержится классификатор другой версии',
  CHECKUP_NOT_FOUND: 'Проверка не найдена',
  NAME_REQUIRED: 'Имя обязательно',
  RULE_NOT_FOUND: 'Правило не найдено',
  DIRECT_GROUP_CANNOT_BE_MODIFIED:
    'Вы не можете добавить элементы в папку перенесенную из пользовательского классификатора',
  INDIRECT_GROUP_CANNOT_BE_MODIFIED:
    'Вы не можете добавить элементы в папку перенесенную из пользовательского классификатора',
  FOLDER_CANNOT_BE_MOVED_TO_SUBFOLDER:
    'Нельзя переносить родителький элемент в дочерний',
  GROUP_CANNOT_BE_ADDED_TO_ITS_SUBGROUP:
    'Нельзя переносить родителькую папку в дочернюю',
  PERMISSION_DENIED: 'Авторизация невозможна. Обратитесь к вашему менеджеру',
  NOT_AUTHORIZED: 'Необходимо авторизоваться',
  BAD_CREDENTIALS: 'Неправильный логин или пароль',
  ACCOUNT_DISABLED: 'Авторизация невозможна. Обратитесь к вашему менеджеру',
  PASSWORD_NOT_SET:
    'Пожалуйста, смените временный пароль на постоянный и войдите с ним',
  CLASSIFIER_FOLDER_NAME_IS_NOT_SPECIFIED: 'Имя папки не может быть пустым',
  ASSEMBLY_EXECUTION_SECOND_TIME:
    'В данной версии запуск сборки возможен 1 раз',
  GENERATE_ASSEMBLY_EXECUTION_MAX_ELEMENT_COUNT:
    'Суммарное количество элементов в сборке не должно превышать 2 млн., пожалуйста уменьшите количество элементов',
  CONDITION_ALREADY_EXIST_IN_THE_GROUP_BRANCH:
    'Выбранная группировка уже задана в цепочке папок',
  MODEL_IS_CORRUPTED:
    'В зависимостях есть модель с ошибкой. Обратитесь в техподдержку, чтобы продолжить работу.',
}
