import { createBimFileSubscriptions } from '@gmini/common/lib/classifier-editor/bimFileSubscriptions'
import { NotificationService } from '@gmini/sm-api-sdk'

import { wsUrl } from '../config'

export const notificationService = NotificationService.create()

notificationService.connect({ url: wsUrl })

export const { resetBimFileIds } = createBimFileSubscriptions({
  subscribe: notificationService.subscriptions.subscribeBimFile,
  unsubscribe: notificationService.subscriptions.unsubscribeBimFile,
})
