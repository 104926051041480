import styled, { css } from 'styled-components'

import { Link as RouterLink } from 'react-router-dom'

type BrandContainerProps = {
  active?: boolean
}

export const BrandContainer = styled(RouterLink)`
  display: flex;
  align-items: center;
  position: relative;
  height: 50px;
  margin-right: 6px;
  min-height: 100%;
  background: inherit;
  color: #00003d;
  font-size: 12px;
  font-family: Roboto, sans-serif;
  letter-spacing: 0.1px;
  font-weight: 700;
  line-height: 21px;
  text-transform: uppercase;
  text-decoration: none;
  cursor: default;
  transition: 0.2s ease-out background-color;
  padding: 8px 18px;
  border-radius: 4px;

  &:hover {
    background: #e5e7f1;
  }

  ${(props: BrandContainerProps) =>
    props.active &&
    css`
      background: #e5e7f1;
    `}
`

export const BrandTitle = styled.div`
  margin-right: 8px;
`

export const BrandSecondaryWrapper = styled.div`
  padding: 0 24px;
`
