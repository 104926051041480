import React from 'react'
import {
  CssBaseline,
  ThemeProvider,
  Theme,
  StyledEngineProvider,
} from '@mui/material'
import { DndProvider } from 'react-dnd'
import MultiBackend from 'react-dnd-multi-backend'
import { Helmet } from 'react-helmet'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { root } from 'effector-root'
import { AuthClientTokens } from '@react-keycloak/core/lib/types'

import { createInspector } from 'effector-logger/inspector'
import { attachLogger } from 'effector-logger/attach'

import {
  OfflineModal,
  DefaultSnackbarProvider,
  PageTitleOptions,
  muiTheme,
  Maintenance,
} from '@gmini/ui-kit'

import { Page404 } from '@gmini/ui-kit/lib/Page404'

import { ApiCallService } from '@gmini/api-call-service'
import { FetchService } from '@gmini/utils'

import { useMaintenance } from '@gmini/common/lib/hooks/useMaintenance'

import { MetrikaProvider } from '@gmini/common/lib/metrika/provider'

import { AuthContent } from '@gmini/common/lib/keycloakAuth/AuthContent'

import { logApiError } from '@gmini/sm-api-sdk/lib/logApiError'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

import { ReactKeycloakProvider } from '@react-keycloak/web'

import { saveAuthData } from '@gmini/common/lib/keycloakAuth/auth'

import { notificationService } from './services/notificationService'

import { CustomHTML5toTouch } from './components/dnd'

import { Notifications } from './components/Notifications'
import { ClassifierExplorerPage } from './components/ClassifierExplorerPage'

import * as config from './config'
import { UserClassifierEditorPageWrap } from './components/UserClassifierEditorPage/UserClassifierEditorPageWrap'
import { EditorByVersionWrap } from './components/UserClassifierEditorPage/EditorByVersionWrap'
import { AssemblyEditorPageWrap } from './components/AssemblyClassifierEditorPage'
import { EditorByVersionAssemblyWrap } from './components/AssemblyClassifierEditorPage/EditorByVersionWrap'

import '@mui/styles'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

if (config.logEffector) {
  attachLogger(root)
  createInspector()
}

export const App = (): JSX.Element => {
  const { maintenanceEnabled } = useMaintenance({
    fetchServiceClass: FetchService,
  })

  const onTokens = React.useCallback(
    ({ idToken, refreshToken, token }: AuthClientTokens) => {
      if (!token || !refreshToken || !idToken) {
        // Если приходят пустые токены, то значит юзер разлогинился или залогинился под другим юзернеймом на другой вкладке
        // и кейклок сам сделает редирект на страницу авторизации что бы обновить куки
        return
      }

      saveAuthData({
        accessToken: token,
        refreshToken,
        idToken,
      })
    },
    [],
  )

  return (
    <ReactKeycloakProvider
      authClient={config.keycloakClient}
      onTokens={onTokens}
      initOptions={{
        onLoad: 'login-required',
        checkLoginIframe: false,
      }}
    >
      <MetrikaProvider>
        <BrowserRouter>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={muiTheme}>
                <DefaultSnackbarProvider>
                  <PageTitleOptions siteName='Сет Менеджмент' />

                  <CssBaseline />
                  <Helmet>
                    <link
                      rel='stylesheet'
                      href='https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap'
                    />
                  </Helmet>
                  {maintenanceEnabled ? (
                    <Maintenance />
                  ) : (
                    <DndProvider
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      backend={MultiBackend as any}
                      options={CustomHTML5toTouch}
                    >
                      <Notifications />
                      <OfflineModal />
                      <AuthContent appName='Сет Менеджмент'>
                        <Switch>
                          <Route
                            path='/user-classifiers/:id'
                            exact
                            component={UserClassifierEditorPageWrap}
                          />
                          <Route
                            path='/user-classifiers/:id/version/:version'
                            exact
                            component={EditorByVersionWrap}
                          />
                          <Route
                            path='/assembly-classifier/:id'
                            exact
                            component={AssemblyEditorPageWrap}
                          />
                          <Route
                            path='/assembly-classifier/:id/version/:version'
                            exact
                            component={EditorByVersionAssemblyWrap}
                          />
                          <Route
                            path='/sign-in'
                            exact
                            render={() => <Redirect to='/' />}
                          />
                          <Route
                            path='/'
                            exact
                            component={ClassifierExplorerPage}
                          />
                          <Route component={Page404} />
                        </Switch>
                      </AuthContent>
                    </DndProvider>
                  )}
                </DefaultSnackbarProvider>
              </ThemeProvider>
            </StyledEngineProvider>
          </LocalizationProvider>
        </BrowserRouter>
      </MetrikaProvider>
    </ReactKeycloakProvider>
  )
}

App.displayName = 'App'

ApiCallService.fail.watch(({ name, params, error }) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  if (error.status === 'fail' || (error as any).name === 'AbortError') {
    return
  }

  logApiError(error)
})

notificationService.validationError.watch(params => {
  params.errors.forEach(error => {
    console.error(`WebSocket error:\n ${JSON.stringify(error, null, 2)}`)
  })
})
