import { memo, useEffect, useState } from 'react'
import { useStore } from 'effector-react'

import { ReadonlyModeProvider } from '@gmini/common'

import * as smApi from '@gmini/sm-api-sdk'

import { Page404 } from '@gmini/ui-kit/lib/Page404'

import {
  currentAssembly$,
  CurrentAssemblyClassifier,
  useAssemblyProps,
} from '../CurrentAssembly/CurrentAssembly'

import { classifierService } from '../../services/userClassifierService'

import { AssemblyClassifierEditorPage } from './AssemblyClassifierEditorPage'

export const AssemblyEditorPageWrap = memo(() => {
  const currentAssembly = useStore(currentAssembly$)
  const params = useAssemblyProps()
  const readonlyMode = !!currentAssembly?.readOnly
  const [resourceNotFound, setResourceNotFound] = useState(false)

  useEffect(() => {
    classifierService.reset()
  }, [])

  useEffect(() => {
    smApi.Project.fetchList.defaultContext.submit()
    if (params) {
      smApi.AssemblyClassifier.getMostRecent.defaultContext.submit({
        id: params.id,
      })
    }
  }, [params])

  useEffect(() => {
    const subscription = smApi.AssemblyClassifier.getMostRecent.defaultContext.failData.watch(
      ({ res }) => {
        const { errorCode } = res.data.data
        if (errorCode === 'RESOURCE_NOT_FOUND') {
          setResourceNotFound(true)
        }
      },
    )

    return () => {
      subscription.unsubscribe()
    }
  }, [params])

  if (resourceNotFound) {
    return <Page404 />
  }

  if (!currentAssembly) {
    return <CurrentAssemblyClassifier fetch />
  }

  return (
    <>
      <CurrentAssemblyClassifier fetch />

      <ReadonlyModeProvider
        value={{
          enabled: readonlyMode,
          description: '',
        }}
      >
        <AssemblyClassifierEditorPage
          currentAssembly={currentAssembly}
          isCurrentVersion={true}
        />
      </ReadonlyModeProvider>
    </>
  )
})
AssemblyEditorPageWrap.displayName = 'AssemblyEditorPageWrap'
