import React from 'react'

import { PlayCircleFilled } from '@mui/icons-material'
import { Tooltip } from '@gmini/ui-kit'

import { useStore } from 'effector-react'

import { AssemblyClassifierNode } from '@gmini/common/lib/classifier-service/Node'

import * as smApi from '@gmini/sm-api-sdk'

import { IconSpinner, IconWrap, RunAssemblyButton } from './RunAssembly.styled'

export const RunAssembly = ({
  currentAssembly,
  pending: _pending,
  disabled,
}: {
  currentAssembly: AssemblyClassifierNode
  pending: boolean
  disabled: boolean
}) => {
  const runPending = useStore(
    smApi.AssemblyClassifier.runAssembly.defaultContext.pending$,
  )

  const pending = _pending || runPending

  const startAssembly = React.useCallback(() => {
    smApi.AssemblyClassifier.runAssembly.defaultContext.submit({
      id: currentAssembly.id,
      version: currentAssembly.version,
    })
  }, [currentAssembly.id, currentAssembly.version])

  const tooltipTitle = React.useMemo((): string => {
    switch (currentAssembly.status) {
      case 'InProcess':
        return 'Идет создание сборки'
      case 'Error':
        return 'Произошла ошибка'
      case 'Canceled':
        return 'Создание сборки отменено'
      case 'Done':
        return 'Сборка готова'
      default:
        return ''
    }
  }, [currentAssembly])

  return (
    <Tooltip title={tooltipTitle}>
      <RunAssemblyButton
        disabledProp={disabled || pending}
        onClick={() => {
          if (!disabled) {
            startAssembly()
          }
        }}
        data-test-id='createAssembly'
      >
        <IconWrap>
          <PlayCircleFilled fontSize='small' />
          {pending && <IconSpinner size={20} />}
        </IconWrap>
        Создать сборку
      </RunAssemblyButton>
    </Tooltip>
  )
}
