import { createClassifierService } from '@gmini/common/lib/classifier-service'

import { apiErrMessageMap } from '../apiErrMessageMap'

export const classifierService = createClassifierService({
  name: 'classifierService',
  apiErrMessageMap,
})

export const selectDependenciesClassifierService = createClassifierService({
  name: 'selectDependenciesClassifierService',
  apiErrMessageMap,
})
