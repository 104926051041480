import { createSearchModel } from '@gmini/common'
import { combine } from 'effector'

import { classifierService } from '../../../services/userClassifierService'
import { currentUserClassifier$ } from '../../CurrentUserClassifier'
import { treeModel } from '../EditorTreeWrap/model'

import { dependencyTreeModel } from './dependencyTreeModel'

const tree$ = combine(
  dependencyTreeModel.flatTree$,
  treeModel.flatTree$,
  (treeModel, dependencyTreeModel) => [...treeModel, ...dependencyTreeModel],
)

export const { Tags, searchModel } = createSearchModel({
  nodes$: classifierService.nodes$,
  currentEntity$: currentUserClassifier$,
  tree$,
  searchInDynamicGroups: true,
})
