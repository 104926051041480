import { memo, useEffect, useState } from 'react'
import { useStore } from 'effector-react'

import { ReadonlyModeProvider } from '@gmini/common'

import * as smApi from '@gmini/sm-api-sdk'

import { Page404 } from '@gmini/ui-kit/lib/Page404'

import {
  CurrentUserClassifier,
  currentUserClassifier$,
  useUserClassifierProps,
} from '../CurrentUserClassifier'

import { classifierService } from '../../services/userClassifierService'

import { UserClassifierEditorPage } from './UserClassifierEditorPage'

export const UserClassifierEditorPageWrap = memo(() => {
  const currentClassifier = useStore(currentUserClassifier$)
  const readonlyMode = !!currentClassifier?.readOnly
  const params = useUserClassifierProps()
  const [resourceNotFound, setResourceNotFound] = useState(false)

  useEffect(() => {
    classifierService.reset()
  }, [])

  useEffect(() => {
    if (params) {
      smApi.UserClassifier.fetchIsUserClassifierLocked.defaultContext.submit({
        id: params.id,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id])

  useEffect(() => {
    smApi.Project.fetchList.defaultContext.submit()
    if (params) {
      smApi.UserClassifier.getMostRecent.defaultContext.submit({
        id: params.id,
      })
    }
  }, [params])

  useEffect(() => {
    const subscription = smApi.UserClassifier.getMostRecent.defaultContext.failData.watch(
      ({ res }) => {
        const { errorCode } = res.data.data
        if (errorCode === 'RESOURCE_NOT_FOUND') {
          setResourceNotFound(true)
        }
      },
    )

    return () => {
      subscription.unsubscribe()
    }
  }, [params])

  if (resourceNotFound) {
    return <Page404 />
  }

  if (!currentClassifier) {
    return <CurrentUserClassifier fetch />
  }

  return (
    <>
      <CurrentUserClassifier fetch />

      <ReadonlyModeProvider
        value={{
          enabled: readonlyMode,
          description: readonlyMode
            ? 'Классификатор находится в режиме просмотра'
            : '',
        }}
      >
        <UserClassifierEditorPage
          currentClassifier={currentClassifier}
          isCurrentVersion={true}
        />
      </ReadonlyModeProvider>
    </>
  )
})
UserClassifierEditorPageWrap.displayName = 'CheckupEditorPageWrap'
