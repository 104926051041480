import { createEffect, createStore } from 'effector'

import * as smApi from '@gmini/sm-api-sdk'
import { broadcast } from '@gmini/utils'

import { notificationService } from '../notificationService'

import {
  classifierService,
  selectDependenciesClassifierService,
} from './service'

export const checkVersionReq = smApi.UserClassifier.getMostRecentSilent.createContext(
  'checkVersion',
)

export const checkVersion = createEffect({
  handler: async ({ id, version }: { id: number; version: number }) => {
    const actual = await checkVersionReq({ id })
    const active = actual.version < version ? false : actual.version !== version
    return { active }
  },
})

export const oldVersionMode$ = createStore<{ active: boolean } | null>(null)
  .on(checkVersion.doneData, (_, value) => value)
  .on(checkVersion.failData, () => ({ active: false }))
  .on(smApi.UserClassifier.getMostRecent.doneData, () => null)

broadcast(
  notificationService.message.filter({ fn: smApi.NotificationEvent.is }),
  [
    classifierService.notification,
    selectDependenciesClassifierService.notification,
  ],
)
