import { combine } from 'effector'

import { currentAssembly$ } from '../../CurrentAssembly/CurrentAssembly'
import { projectList$ } from '../../UserClassifierEditorPage/core/project'

export const currentProjectAssembly$ = combine({
  projects: projectList$,
  classifier: currentAssembly$,
}).map(
  ({ classifier, projects }) =>
    projects?.find(project => project.urn === classifier?.projectUrn) || null,
)
