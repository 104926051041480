import * as React from 'react'
import { createGate, useStore } from 'effector-react'
import { useParams, useHistory, Redirect } from 'react-router-dom'
import { combine, Store } from 'effector'

import { equals } from 'ramda'

import { node } from '@gmini/common/lib/classifier-service'

import { classifierService } from '../../services/userClassifierService'

export interface AssemblyClassifierProps {
  readonly id: number
  readonly version: number
}

const CurrentAssemblyGate = createGate<null | {
  readonly id: number
}>({
  name: 'CurrentAssemblyGate',
  defaultState: null,
})

export const assemblyPropsFromLocation$: Store<null | {
  readonly id: number
}> = CurrentAssemblyGate.state

export const currentAssembly$: Store<null | node.AssemblyClassifierNode> = combine(
  classifierService.assemblyClassifier.nodes$,
  assemblyPropsFromLocation$,
  (nodes, props) => (props ? nodes[props.id] || null : null),
).map((currentAssembly, oldValue = null) =>
  equals(currentAssembly, oldValue) ? oldValue : currentAssembly,
)

export function useCurrentAssembly(): null | node.AssemblyClassifierNode {
  return useStore(currentAssembly$)
}

export function useAssemblyProps() {
  const { id, version } = useParams<{
    readonly id?: string
    readonly version?: string
  }>()
  return React.useMemo(
    () =>
      id ? ({ id: +id, version: version ? +version : null } as const) : null,
    [id, version],
  )
}

export function getAssemblyPath({ id }: { readonly id: number }): string {
  return `/assembly-classifier/${id}`
}

export function useGoToAssembly() {
  const history = useHistory()
  return React.useCallback(
    (props: AssemblyClassifierProps) => history.push(getAssemblyPath(props)),
    [history],
  )
}

export const CurrentAssemblyClassifier = React.memo<{
  readonly fetch?: boolean
}>(({ fetch }) => {
  const assemblyProps = useAssemblyProps()

  if (!assemblyProps) {
    return <Redirect to='/' />
  }

  return <CurrentAssemblyGate id={assemblyProps.id} />
})

CurrentAssemblyClassifier.displayName = 'CurrentAssemblyClassifier'
