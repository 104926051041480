import { combine, Store } from 'effector'

import { getNode, Nodes } from '@gmini/common/lib/classifier-service'

import { AssemblyClassifierNode } from '@gmini/common/lib/classifier-service/Node'

import { createChildTree, FlatNode } from '@gmini/common/lib/classifier-editor'

export type TreeModel = {
  flatTree$: Store<FlatNode[]>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const emptyArray: any[] = []

export function createAssemblyTreeModel({
  nodes$,
  currentAssembly$,
}: {
  nodes$: Store<Nodes>
  currentAssembly$: Store<AssemblyClassifierNode | null>
}): TreeModel {
  const flatTree$ = combine(
    {
      nodes: nodes$,
      assembly: currentAssembly$,
    },
    ({ nodes, assembly }) => {
      if (
        !assembly ||
        !assembly.assemblyModelRef ||
        assembly.status !== 'Done' ||
        // GT-619 Сборки. Ошибки построения правого дерева в консоли
        !getNode(nodes, assembly.assemblyModelRef.element)
      ) {
        return []
      }

      const flatTree = createChildTree({
        nodes,
        rootNodeRefs: [assembly.assemblyModelRef],
      })

      return flatTree
    },
  )
  return { flatTree$ }
}
