import styled from 'styled-components'

import { CircularProgress } from '@gmini/ui-kit/lib/CircularProgress'

const Button = styled.button`
  border: none;
  font-size: 12px;
  text-transform: none;
  background-color: rgb(53, 59, 96);
  padding: 6px 8px;
  cursor: pointer;
  box-sizing: inherit;
  border-radius: 4px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  line-height: 1.75;
`

type RunAssemblyButtonProps = {
  disabledProp?: boolean
}

export const RunAssemblyButton = styled(Button)`
  background-color: #353b60;
  display: flex;
  align-items: center;
  color: #ffffff;
  text-transform: none;
  transition: opacity 0.3s ease 0s;
  opacity: ${(props: RunAssemblyButtonProps) => (props.disabledProp ? 0.7 : 1)};
  &:hover {
    opacity: ${(props: RunAssemblyButtonProps) =>
      props.disabledProp ? 0.7 : 0.9};
    background-color: #353b60;
    color: #ffffff;
  }
`

export const IconWrap = styled.div`
  display: inline-flex;
  min-width: 24px;
`

export const IconSpinner = styled(CircularProgress)`
  color: inherit;
  position: absolute;
`
