import { combine, restore } from 'effector'
import * as smApi from '@gmini/sm-api-sdk'

import { currentUserClassifier$ } from '../../CurrentUserClassifier'

export const projectList$ = restore(
  smApi.Project.fetchList.defaultContext.doneData.map(({ list }) => list),
  null,
)

export const currentProject$ = combine({
  projects: projectList$,
  classifier: currentUserClassifier$,
}).map(
  ({ classifier, projects }) =>
    projects?.find(project => project.urn === classifier?.projectUrn) || null,
)
