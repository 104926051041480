import * as React from 'react'
import { createGate, useStore } from 'effector-react'
import { useParams, Redirect } from 'react-router-dom'
import { combine, Store } from 'effector'

import { equals } from 'ramda'

import { node } from '@gmini/common/lib/classifier-service'

import { classifierService } from '../../services/userClassifierService'

export interface UserClassifierProps {
  readonly id: number
  readonly version: number
}

const CurrentUserClassifierGate = createGate<null | {
  readonly id: number
}>({
  name: 'CurrentUserClassifierGate',
  defaultState: null,
})

export const userClassifierPropsFromLocation$: Store<null | {
  readonly id: number
}> = CurrentUserClassifierGate.state

export const currentUserClassifier$: Store<null | node.UserClassifierNode> = combine(
  classifierService.userClassifier.nodes$,
  userClassifierPropsFromLocation$,
  (nodes, props) => (props ? nodes[props.id] || null : null),
).map((currentUserClassifier, oldValue = null) =>
  equals(currentUserClassifier, oldValue) ? oldValue : currentUserClassifier,
)

export const projectUrn$ = currentUserClassifier$.map(classifier =>
  classifier ? classifier.projectUrn : null,
)

export function useCurrentUserClassifier(): null | node.UserClassifierNode {
  return useStore(currentUserClassifier$)
}

export function useUserClassifierProps() {
  const { id, version } = useParams<{
    readonly id?: string
    readonly version?: string
  }>()

  return React.useMemo(
    () =>
      id ? ({ id: +id, version: version ? +version : null } as const) : null,
    [id, version],
  )
}

export function getUserClassifierPath({ id }: { readonly id: number }): string {
  return `/user-classifiers/${id}`
}

export const CurrentUserClassifier = React.memo<{
  readonly fetch?: boolean
}>(({ fetch }) => {
  const userClassifierProps = useUserClassifierProps()

  if (!userClassifierProps) {
    return <Redirect to='/' />
  }

  return <CurrentUserClassifierGate id={userClassifierProps.id} />
})

CurrentUserClassifier.displayName = 'CurrentUserClassifier'
