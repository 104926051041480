import React from 'react'
import { Box } from '@gmini/ui-kit/lib/Box'

import { combine, createEvent, guard, merge, sample, Store } from 'effector'
import { useSnackbar } from 'notistack'

import { useStore, useStoreMap } from 'effector-react'

import { useKeycloak } from '@react-keycloak/web'

import {
  PageContentContainer,
  useNavbar,
  ProjectBadge,
} from '@gmini/common/lib/components'

import { createModelStoreService } from '@gmini/common/lib/classifier-editor/ModelSelect/modelStore'

import { prop } from 'ramda'

import {
  HeaderLogoWrap,
  Logo,
  Resizable,
  ResizablePanel,
  IconButton,
  PlusCircle,
  Tooltip,
  Cover,
  Substrate,
  WithCursorIcon,
} from '@gmini/ui-kit'
import { Header } from '@gmini/ui-kit/lib/Header/Header'

import { isNotEmpty } from '@gmini/utils'

import {
  AssemblyClassifierNode,
  isBimModelNode,
  isReferenceNode,
} from '@gmini/common/lib/classifier-service/Node'

import { getNode } from '@gmini/common/lib/classifier-service'

import * as filter from '@gmini/common/lib/classifier-editor/DependencyTree/Filters/InclusionFilter/FilterModel'

import { createExpandModel } from '@gmini/common/lib/classifier-editor/ClassifierTree/model/expandModel'

import { createModelsTree } from '@gmini/common/lib/classifier-editor/SelectDependencies/ModelsTree'

import {
  DragLayer,
  ModelSelect,
  openExplorer,
  SelectDependencies,
  isApiFlatNode,
  createSelectDependenciesParams,
  selectedModels as selectedModelsService,
  createSearchModel,
  UnionTree,
} from '@gmini/common/lib/classifier-editor'

import * as forgeViewer from '@gmini/common/lib/forge-viewer'

import { createModelManageMenu } from '@gmini/common/lib/classifier-editor/ModelManageMenu' // TODO move to classifier editor

import {
  useReadonlyMode,
  VersionButton,
  VersionHistory,
  getModulesLinkFromEnv,
} from '@gmini/common'

import { goals } from '@gmini/common/lib/metrika'

import { createStatusPanel } from '@gmini/common/lib/classifier-editor/StatusPanel'

import { userInfo$ } from '@gmini/common/lib/auth/auth'

import * as smApi from '@gmini/sm-api-sdk'

import { createFetchDependenciesService } from '@gmini/common/lib/classifier-editor/DependencyTree/model'
import { getFilterIsActiveStore } from '@gmini/common/lib/components/FilterPanel/utils/getFilterIsActiveStore'

import {
  useNamedVersions,
  useVersionName,
} from '@gmini/common/lib/components/VersionSwitch/NamedVersions'
import { VersionList } from '@gmini/common/lib/components/VersionSwitch/VersionList'
import {
  compareDates,
  fromDateTimeToDate,
  sortByDate,
} from '@gmini/common/lib/helpers/versionDateCommon'
import { createVersionHistoryStore } from '@gmini/common/lib/components/VersionSwitch/versionHistoryStore'
import { useContextMenu } from '@gmini/common/lib/components/VersionSwitch/ContextMenu'
import { createViewerDisplayModel } from '@gmini/common/lib/classifier-editor/FromTreeToViewer/Model'
import { getBimNode } from '@gmini/common/lib/classifier-editor/Search/utils'

import {
  getViewerId,
  validateModelTypes,
  getViewerModelUrl,
  fetchViewerToken,
} from '@gmini/common/lib/classifier-editor/Common/utils'

import { adapter } from '@gmini/common/lib/classifier-service/adapters'

import { TreeLoader } from '@gmini/ui-kit/lib/TreeLoader/TreeLoader'

import {
  notificationService,
  resetBimFileIds,
} from '../../services/notificationService'

import { classifierService } from '../../services/userClassifierService'

import { editorCheckedModel } from '../UserClassifierEditorPage/model/checkedModel'

import { dependencyExpandModel } from '../UserClassifierEditorPage/model/dependencyExpandModel'
import './core/init'
import { currentAssembly$ } from '../CurrentAssembly/CurrentAssembly'

import { envLinks, seoEventManager } from '../../config'

import { assemblyTreeModel } from './model/assemblyTreeModel'
import { DependencyTreeWrap } from './DependencyTreeWrap'
import { currentProjectAssembly$ } from './core/project'
import { viewerDerivatives$ } from './core/fetchViewerModelDerivatives'

import {
  assemblyCheckedModel,
  dependencyCheckedModel,
} from './model/checkedModel'
import { AssemblyTreeWrap } from './AssemblyTreeWrap'
import { inclusionStore$ } from './model/inclusionModel'
import { dependencyTreeModel } from './model/dependencyTreeModel'
import { RunAssembly } from './RunAssembly/RunAssembly'
import { searchSourceModel } from './model/searchSourceModel'

import {
  BrandContainer,
  BrandTitle,
  BrandSecondaryWrapper,
} from './AssemblyClassifierEditorPage.styled'

import { filterPanelService } from './model/filterPanelService'
import {
  SelectSourcePanel,
  selectedSourceNodes$,
} from './model/selectSourcePanel.model'

const { nodes$ } = classifierService

const { message, subscriptions } = notificationService

const notification = message.filter({
  fn: smApi.NotificationEvent.is,
})

const assemblyPending$ = combine(
  [
    smApi.AssemblyClassifier.addDependency.defaultContext.pending$,
    smApi.AssemblyClassifier.removeDependency.defaultContext.pending$,
    smApi.AssemblyClassifier.runAssembly.defaultContext.pending$,
    //FSK-890 тут должен лежать эндпоинт создания сборки
  ],
  pending => pending.some(Boolean),
)

const {
  addedDependencyIds$,
  currentModelsByClassifierMap$,
} = createSelectDependenciesParams({
  nodes$: classifierService.nodes$,
  currentEntity$: currentAssembly$,
})

const { ModelsTree, bimFile$ } = createModelsTree({
  addedDependencyIds$,
  inclusionStatus$: inclusionStore$,
  currentModelsByClassifierMap$,
  currentEntity$: currentAssembly$,
  notification,
})

const { ModelManageMenu } = createModelManageMenu({
  classifierService,
  currentEntity$: currentAssembly$,
  bimFile$,
})

const dependencyRootNodes$ = sample({
  source: classifierService.nodes$,
  clock: dependencyTreeModel.flatTree$,
  fn: (nodes, flatTree) =>
    flatTree
      .filter(isApiFlatNode)
      .filter(({ path }) => path.length === 1)
      .map(({ ref }) => getNode(nodes, ref))
      .filter(isNotEmpty),
})

const assemblyExpandModel = createExpandModel()

const modelsLoaded$ = sample({
  source: classifierService.nodes$,
  clock: dependencyRootNodes$,
  fn: (nodes, rootNodes) =>
    !!rootNodes.length &&
    rootNodes.every(_node => {
      const node = isReferenceNode(_node)
        ? getNode(nodes, _node.element)
        : _node

      return node && isBimModelNode(node) && node.status === 'ImportCompleted'
    }),
})

export const expandVersionModel = createExpandModel()

const selectedModels$ = selectedModelsService.models$.map(
  models => models?.map(({ viewerId }) => viewerId) || [],
)

const modelStoreService = createModelStoreService((node, viewerRef) =>
  getViewerId({
    node,
    viewerRef,
    nodes: classifierService.nodes$.getState(),
    getNodeFunc: getNode,
  }),
)

const entityUpdated = sample({
  clock: notificationService.message
    .filter({ fn: smApi.NotificationEvent.Update.is })
    .map(prop('payload'))
    .filter({ fn: smApi.AssemblyClassifier.is }),
  source: currentAssembly$,
  fn: (currentCls, updatedCls) =>
    updatedCls.id === currentCls?.id ? updatedCls : null,
})

const fetchDependenciesReset = createEvent<void>()

createFetchDependenciesService({
  entity$: currentAssembly$,
  entityUpdated,
  fetchedClassifiers: classifierService.dependencies.update.doneData.map(
    ({ BaseClassifierNode, UserClassifierNode, AssemblyClassifierNode }) => [
      ...Object.values(BaseClassifierNode),
      ...Object.values(UserClassifierNode),
      ...Object.values(AssemblyClassifierNode),
    ],
  ),
  reset: fetchDependenciesReset,
  nodes$: classifierService.nodes$,
})

const { versionDates$, versions$, removeVersion } = createVersionHistoryStore({
  fetchDates: smApi.AssemblyClassifier.fetchVersionDates.doneData,
  fetchVersions: smApi.AssemblyClassifier.fetchVersionByDate.done.map(
    ({ params, result: { versions } }) => ({
      versionDate: params.versionDate,
      versions,
    }),
  ),
  fetchNamedVersionDates: smApi.AssemblyClassifier.fetchNamedVersions.doneData.map(
    ({ versions }) => ({
      versionDates: [
        ...new Set(
          versions.map(version => fromDateTimeToDate(version.createdDate)),
        ),
      ],
    }),
  ),
  fetchNamedVersions: smApi.AssemblyClassifier.fetchNamedVersions.doneData.map(
    ({ versions }) =>
      versions.map(version => ({
        versionDate: fromDateTimeToDate(version.createdDate),
        versions: versions
          .filter(vrs => compareDates(vrs.createdDate, version.createdDate))
          .sort((a, b) => sortByDate(a.createdDate, b.createdDate)),
      })),
  ),
  versionNameChanged: merge([
    smApi.AssemblyClassifier.renameVersion.doneData,
    smApi.AssemblyClassifier.removeVersionName.doneData,
  ]).map(data => ({
    versionDate: fromDateTimeToDate(data.createdDate),
    version: data,
  })),
})

const versionHistoryPending$ = combine(
  [
    smApi.AssemblyClassifier.fetchNamedVersions.defaultContext.pending$,
    smApi.AssemblyClassifier.fetchVersionDates.defaultContext.pending$,
  ],
  pendings => pendings.some(Boolean),
)

sample({
  source: currentAssembly$,
  clock: merge([
    smApi.AssemblyClassifier.renameVersion.doneData,
    smApi.AssemblyClassifier.removeVersionName.doneData,
  ]),
  fn: (estimation, versionData) => ({ estimation, versionData }),
}).watch(({ estimation, versionData }) => {
  if (estimation?.version === versionData.version) {
    smApi.AssemblyClassifier.fetch.defaultContext(estimation)
  }
})

const tree$ = combine(
  dependencyTreeModel.flatTree$,
  assemblyTreeModel.flatTree$,
  (depTree, treeModel) => [...depTree, ...treeModel],
  //TODO Разобраться с createSearchModel и типом tree$
) as Store<UnionTree>

export const { Tags, searchModel } = createSearchModel({
  nodes$: classifierService.nodes$,
  currentEntity$: currentAssembly$,
  tree$,
})

export const { StatusPanel: DependenciesStatusPanel } = createStatusPanel({
  checkedModel: dependencyCheckedModel,
  flatTree$: dependencyTreeModel.flatTree$,
  searchNodesInfo$: searchModel.searchNodes$,
  openedFilterPanel$: filterPanelService.openedFilterPanel$,
  hideIsolateButton$: getFilterIsActiveStore(searchModel.searchNodes$),
  SelectSourcePanel,
  selectedSourceNodes$,
})
const searchedBimNodes$ = sample({
  source: classifierService.nodes$,
  clock: searchModel.searchNodes$,
  fn: (nodes, search) => {
    if (!search?.nodes?.length) {
      return null
    }
    return search.nodes
      .map(({ node }) => getBimNode(node, nodes))
      .filter(isNotEmpty)
  },
})

// Инкапсулировать логику в common после GT-984 - Front: Исправить проблему: "circular dependencies" в репозиториях
const modelUpdated = message
  .filter({ fn: smApi.NotificationEvent.Update.is })
  .map(prop('payload'))
  .filter({ fn: smApi.BimModel.is })
  .filter({
    fn: model =>
      model.modelType === 'ForgeModel' && model.status === 'ImportCompleted',
  })

guard({
  clock: modelUpdated,
  source: currentAssembly$.map(cls =>
    cls ? { classifierId: cls.id, classifierVersion: cls.version } : null,
  ),
  filter: Boolean,
  target: smApi.UserClassifier.fetchFlatListDependencies.defaultContext.submit,
})

createViewerDisplayModel({
  currentEntity$: currentAssembly$,
  nodes$: classifierService.nodes$,
  searchModel,
  getViewerId: (node, viewerRef) =>
    getViewerId({
      node,
      viewerRef,
      nodes: classifierService.nodes$.getState(),
      getNodeFunc: getNode,
    }),
  getClsModels: (clsId, clsVersion) => {
    const dependenciesWithModels = modelStoreService.dependenciesWithModels$.getState()
    const models =
      dependenciesWithModels
        .find(
          ({ classifier }) =>
            classifier.id === clsId && classifier.version === clsVersion,
        )
        ?.models.map(m => adapter(m)) || null

    if (!models) {
      throw new Error(
        `Models not found in classifier id: ${clsId}, version: ${clsVersion}`,
      )
    }

    // Now we are sure that model arr contain  only bim360 or Gstation models
    validateModelTypes(models)

    return models
  },
})

export const { StatusPanel: AssemblyTreeStatusPanel } = createStatusPanel({
  checkedModel: assemblyCheckedModel,
  flatTree$: assemblyTreeModel.flatTree$,
  searchNodesInfo$: searchModel.searchNodes$,
  openedFilterPanel$: filterPanelService.openedFilterPanel$,
})

export const AssemblyClassifierEditorPage = React.memo(
  ({
    currentAssembly,
    isCurrentVersion,
  }: {
    currentAssembly: AssemblyClassifierNode
    isCurrentVersion: boolean
  }) => {
    const userInfo = useStore(userInfo$)

    const assemblyPending = useStore(assemblyPending$)
    const baseClsPending = useStore(
      smApi.BaseClassifier.Populated.fetch.defaultContext.pending$,
    )

    const selectedModels = useStore(selectedModels$)
    const [selectViewerRefs, setSelectViewerRefs] = React.useState<
      Record<string, string[]>
    >({})

    const fetchFlatListDependenciesPending = useStore(
      smApi.UserClassifier.fetchFlatListDependencies.defaultContext.pending$,
    )
    const fetchFlatListItemsPending = useStore(
      smApi.UserClassifier.fetchFlatListItems.defaultContext.pending$,
    )
    const renameAssemblyPending = useStore(
      smApi.AssemblyClassifier.rename.defaultContext.pending$,
    )

    React.useEffect(
      () => () => modelStoreService.resetDependenciesWithModels(),
      [],
    )

    React.useEffect(() => {
      const subscription = searchModel.resetSearchNodes.watch(() => {
        setSelectViewerRefs({})
      })

      return () => {
        subscription.unsubscribe()
      }
    }, [])

    React.useEffect(
      () => () => {
        classifierService.reset()
        resetBimFileIds()
        fetchDependenciesReset()
      },
      [],
    )

    React.useEffect(() => {
      if (currentAssembly?.id && currentAssembly?.version) {
        smApi.DependencyWithModels.getClassifierDependencyModels.defaultContext(
          {
            id: currentAssembly.id,
            version: currentAssembly.version,
          },
        )
      }
    }, [currentAssembly?.id, currentAssembly?.version])

    const { id } = currentAssembly

    React.useEffect(() => {
      if (id) {
        subscriptions.subscribeUserClassifier({ id })
      }
      return () => {
        if (id) {
          subscriptions.unsubscribeUserClassifier({ id })
        }
      }
    }, [id])

    React.useEffect(() => {
      filter.setStorageKey(`${currentAssembly.id}`)

      return filter.reset
    }, [currentAssembly])

    const { readonlyMode } = useReadonlyMode()
    const { keycloak } = useKeycloak()

    const editorChecked = useStore(editorCheckedModel.checked$)
    const dependencyChecked = useStore(dependencyCheckedModel.checked$)
    const modelsLoaded = useStore(modelsLoaded$)
    const fetchListProjectPending = useStore(
      smApi.Project.fetchList.defaultContext.pending$,
    )

    const currentProjectAssembly = useStore(currentProjectAssembly$)
    const viewerDerivatives = useStore(viewerDerivatives$)

    const assemblyBuilding =
      currentAssembly.status === 'InProcess' ||
      currentAssembly.status === 'Pending'

    //Отсортированный sourceClassifiers без суррогатной модели
    const currentDependencies = useStoreMap({
      store: nodes$,
      keys: [currentAssembly],
      fn: (nodes, [deps]) =>
        deps?.sourceClassifiers
          ?.map(node => getNode(nodes, node))
          .filter(source => {
            const modelRefRef = source?.children[0]
            if (!modelRefRef) {
              return true
            }

            const modelRef = getNode(nodes, modelRefRef)

            // Костыль: убираем из зависимостей "суррогатную" модель
            if (
              modelRef &&
              isReferenceNode(modelRef) &&
              currentAssembly?.type === 'AssemblyClassifierNode' &&
              currentAssembly?.assemblyModelRef?.element.id ===
                modelRef.element.id
            ) {
              return false
            }

            return true
          })
          .filter(isNotEmpty),
    })

    const versionHistoryPending = useStore(versionHistoryPending$)

    const versionDates = useStore(versionDates$)
    const versions = useStore(versions$)

    const {
      NamedVersionsSwitch,
      namedVersions,
      closeNamedVersions,
    } = useNamedVersions({
      getNamedVersions: async () =>
        !!(await smApi.AssemblyClassifier.fetchNamedVersions.defaultContext({
          classifierId: currentAssembly!.id,
        })),
      onDisable: () => {
        expandVersionModel.resetExpanded()
        smApi.AssemblyClassifier.fetchVersionDates.defaultContext({
          classifierId: currentAssembly!.id,
        })
      },
      onActive: () =>
        versionDates && expandVersionModel.expandAll(versionDates.versionDates),
      disabled: versionHistoryPending,
    })

    const [openVersionHistory, setOpenVersionHistory] = React.useState(false)

    const { enqueueSnackbar } = useSnackbar()

    const onErrorGetViewerModelUrl = (errorText: string) => {
      enqueueSnackbar(errorText, {
        variant: 'error',
      })
      selectedModelsService.reset()
    }

    const onOpenVersionHistory = React.useCallback(async () => {
      await smApi.AssemblyClassifier.fetchVersionDates.defaultContext({
        classifierId: currentAssembly!.id,
      })

      setOpenVersionHistory(true)
    }, [currentAssembly])

    const onCloseVersionHistory = React.useCallback(() => {
      if (namedVersions) {
        closeNamedVersions()
      }
      setOpenVersionHistory(false)
    }, [closeNamedVersions, namedVersions])

    const {
      ChangeVersionNameDialog,
      setChangeNameDialog,
      changeNameDialog,
    } = useVersionName<smApi.VersionData>({
      onSubmitChangeName: async ({ name, version }) =>
        !!(await smApi.AssemblyClassifier.renameVersion.defaultContext({
          classifierId: currentAssembly!.id,
          classifierVersion: version,
          versionName: name,
        })),
    })

    const { ContextMenu, setCtxMenu } = useContextMenu<smApi.VersionData>([
      {
        title: 'Перейти к версии',
        onClick: ({ version }) => {
          window.open(
            `${window.location.origin}/assembly-classifier/${
              currentAssembly!.id
            }/version/${version}`,
            '_blank',
          )
        },
        show: item => item.version !== currentAssembly!.version,
      },
      {
        title: 'Переименовать',
        onClick: item => {
          setChangeNameDialog(item)
        },
        show: item => !!item.name,
      },
      {
        title: 'Присвоить имя',
        onClick: item => {
          setChangeNameDialog(item)
        },
        show: item => !item.name,
      },
      {
        title: 'Удалить название',
        onClick: item => {
          smApi.AssemblyClassifier.removeVersionName
            .defaultContext({
              classifierId: currentAssembly!.id,
              classifierVersion: item.version,
            })
            .then(version => {
              if (namedVersions) {
                removeVersion({
                  versionDate: fromDateTimeToDate(version.createdDate),
                  version,
                })
              }
            })
        },
        show: item => !!item.name,
      },
    ])

    const onRenameEntity = (value: string) => {
      smApi.AssemblyClassifier.rename.defaultContext.submit({
        name: value,
        parentFolderId: currentAssembly.parentFolderId,
        id: currentAssembly.id,
        version: currentAssembly.version,
      })
    }

    const { NavbarDropDown, opened } = useNavbar({
      navModules: getModulesLinkFromEnv(
        envLinks,
        currentProjectAssembly?.urn,
        currentProjectAssembly?.sourceType,
      ),
      seoEventHandler: (selectedExplorer: string) => {
        seoEventManager.push({
          event: 'ExplorersNavigation_SelectExplorer',
          payload: {
            originalExplorer: 'Set-management',
            selectedExplorer,
          },
        })
      },
    })

    const projectBadge = (
      <ProjectBadge
        projectName={currentProjectAssembly?.name || ''}
        loading={fetchListProjectPending}
      />
    )

    if (!currentProjectAssembly) {
      return null
    }

    return (
      <>
        <DragLayer
          editorCheckedCount={Object.keys(editorChecked).length}
          depsCheckedCount={Object.keys(dependencyChecked).length}
        />
        <Box display='flex' flexDirection='column' height='100%'>
          <Header
            onLogout={() => keycloak.logout()}
            title={currentAssembly.name}
            brandSecondary={
              <BrandSecondaryWrapper>{projectBadge}</BrandSecondaryWrapper>
            }
            titleSecondary={
              <>
                <VersionButton
                  onClick={onOpenVersionHistory}
                  isActualVersion={isCurrentVersion}
                  currentVersion={currentAssembly!.version}
                  versionCreated={currentAssembly!.versionCreatedDate}
                  versionName={currentAssembly!.versionName}
                  title={currentAssembly?.name}
                  pending={versionHistoryPending}
                  onRename={onRenameEntity}
                  renamePending={renameAssemblyPending}
                />
                <Box ml={1} flexGrow={1}>
                  <Tags />
                </Box>
                <filterPanelService.FilterButton
                  currentUserClassifier={currentAssembly}
                  searchModel={searchModel}
                />
              </>
            }
            brand={
              <Tooltip
                enterDelay={400}
                title={opened ? '' : 'Выйти к списку инспекций'}
              >
                <BrandContainer
                  data-test-id='backToExplorer'
                  to={
                    currentProjectAssembly
                      ? `/?projectUrn=${currentProjectAssembly?.urn}`
                      : '/'
                  }
                  active={opened}
                >
                  <HeaderLogoWrap>
                    <Logo />
                  </HeaderLogoWrap>
                  <BrandTitle>Set-management</BrandTitle>
                  {NavbarDropDown}
                </BrandContainer>
              </Tooltip>
            }
            userInfo={userInfo}
            onProfileClick={() => goals.showUserMenu()}
          />

          <PageContentContainer data-test-id='UserClassifierEditor'>
            <Resizable
              foldedPanelWidthPx={50}
              storageKey='UserClassifierEditorPanels'
            >
              <ResizablePanel
                header={
                  <Box
                    ml='-16px'
                    maxWidth='calc(100% - 32px)'
                    style={{ overflow: 'hidden' }}
                  >
                    <ModelSelect
                      dependencyRootNodes$={dependencyRootNodes$}
                      currentEntity$={currentAssembly$}
                      searchedBimNodes$={searchedBimNodes$}
                      modelStoreService={modelStoreService}
                      getViewerId={(node, viewerRef) =>
                        getViewerId({
                          node,
                          viewerRef,
                          nodes: classifierService.nodes$.getState(),
                          getNodeFunc: getNode,
                        })
                      }
                    />
                  </Box>
                }
                foldedTitle='Просмотр модели'
                onOpened={() => {
                  goals.expandViewer()
                }}
                onClosed={() => {
                  goals.foldViewer()
                }}
                data-test-id='ModelViewerSection'
                bodyStyles={{ borderRight: '' }}
                hideCssOnlyDN
              >
                <forgeViewer.ViewerComponent
                  selectedModels={selectedModels}
                  resetSearchNodes={searchModel.resetSearchNodes}
                  selectViewerElements={selectViewerRefs}
                  resetSelectedModels={selectedModelsService.reset}
                  loadModelByUrl
                  getModelUrl={modelUrn =>
                    getViewerModelUrl(
                      modelUrn,
                      viewerDerivatives,
                      onErrorGetViewerModelUrl,
                    )
                  }
                  fetchToken={() => fetchViewerToken(keycloak)}
                />
              </ResizablePanel>

              <ResizablePanel
                title='Зависимости'
                header={
                  <Tooltip placement='top' title='Добавить модель(и)'>
                    <IconButton
                      onClick={openExplorer}
                      disabled={
                        !!currentAssembly.readOnly ||
                        assemblyPending ||
                        assemblyBuilding
                      }
                      data-test-id='openDependencyList'
                      data-test-ui-type='CreateButton'
                    >
                      <PlusCircle color='rgba(53, 59, 96, 0.5)' />
                    </IconButton>
                  </Tooltip>
                }
                headerSecondary={<ModelManageMenu />}
                footer={<DependenciesStatusPanel />}
                onOpened={() => {
                  goals.expandDependencies()
                }}
                onClosed={() => {
                  goals.foldDependencies()
                }}
                data-test-id='DependenciesSection'
                body={<></>}
                bodyStyles={{ borderRight: '' }}
                hideCssOnlyVH
              >
                <Box
                  width='100%'
                  height='100%'
                  display='flex'
                  flexDirection='column'
                >
                  {/* TODO FSK-890 оставить одно дерево в конце выполнения таски */}
                  <TreeLoader
                    loading={fetchFlatListDependenciesPending}
                    text='Идет загрузка...'
                  />
                  <Cover
                    open={
                      currentAssembly?.sourceClassifiers?.length === 0 &&
                      !fetchFlatListDependenciesPending
                    }
                    icon={
                      <Substrate>
                        <WithCursorIcon>
                          <PlusCircle color='#DADADA' width={45} height={45} />
                        </WithCursorIcon>
                      </Substrate>
                    }
                    title={
                      <>
                        Добавьте Модели в “Зависимости”
                        <br /> для начала работы
                      </>
                    }
                  />
                  <DependencyTreeWrap
                    currentAssembly={currentAssembly}
                    dependencyCheckedModel={dependencyCheckedModel}
                    dependencyExpandModel={dependencyExpandModel}
                    dependencyTreeModel={dependencyTreeModel}
                    nodes$={classifierService.nodes$}
                    searchModel={searchModel}
                    disableOperations={assemblyBuilding || assemblyPending}
                    currentDependencies={currentDependencies}
                    searchSourceData$={searchSourceModel.searchSourceData$}
                    selectForgeRefs={setSelectViewerRefs}
                    openedFilterPanel$={filterPanelService.openedFilterPanel$}
                    selectedSourceNodes$={selectedSourceNodes$}
                    sources={filterPanelService.sources}
                  />
                  <SelectDependencies
                    ModelsTree={ModelsTree}
                    brand={projectBadge}
                  />
                </Box>
              </ResizablePanel>

              <ResizablePanel
                title='Сборка'
                onOpened={() => {
                  goals.expandClassifier()
                }}
                header={<> </>}
                onClosed={() => {
                  goals.foldClassifier()
                }}
                data-test-id='ElementsSection'
                footer={
                  <AssemblyTreeStatusPanel
                    content={
                      <RunAssembly
                        currentAssembly={currentAssembly}
                        pending={assemblyPending || assemblyBuilding}
                        disabled={
                          !modelsLoaded ||
                          assemblyBuilding ||
                          baseClsPending ||
                          currentAssembly.status === 'Done' ||
                          readonlyMode.enabled
                        }
                      />
                    }
                  />
                }
                bodyStyles={{ borderRight: '' }}
                hideCssOnlyDN
              >
                {/* TODO FSK-890 оставить одно дерево в конце выполнения таски */}
                <TreeLoader
                  loading={fetchFlatListItemsPending}
                  text='Идет загрузка...'
                />
                <AssemblyTreeWrap
                  currentAssembly={currentAssembly}
                  assemblyExpandModel={assemblyExpandModel}
                  assemblyCheckedModel={assemblyCheckedModel}
                  assemblyTreeModel={assemblyTreeModel}
                  nodes$={classifierService.nodes$}
                  searchModel={searchModel}
                  disableOperations={true}
                  currentDependencies={currentDependencies}
                  selectViewerRefs={setSelectViewerRefs}
                />
              </ResizablePanel>
            </Resizable>
          </PageContentContainer>
        </Box>

        <VersionHistory
          open={openVersionHistory}
          onClose={onCloseVersionHistory}
          onRedirectToLast={() => {
            window.location.pathname = `/assembly-classifier/${
              currentAssembly!.id
            }`
          }}
          isCurrentVersion={isCurrentVersion}
          beforeList={NamedVersionsSwitch}
          versionList={
            <VersionList
              onMenuClick={setCtxMenu}
              currentVersion={currentAssembly!.version}
              dates={versionDates}
              versions={versions}
              fetchVersions={async versionDate => {
                await smApi.AssemblyClassifier.fetchVersionByDate.defaultContext(
                  {
                    classifierId: currentAssembly!.id,
                    versionDate,
                  },
                )
              }}
              anotherListOpened={namedVersions}
              contextMenu={!changeNameDialog && ContextMenu()}
              openVersionHistory={openVersionHistory}
              expandModel={expandVersionModel}
            />
          }
        />

        <filterPanelService.FilterPanel
          classifierId={currentAssembly.id}
          classifierVersion={currentAssembly.version}
          searchModel={searchModel}
          nodes$={classifierService.nodes$}
          selectViewerRefs={setSelectViewerRefs}
          currentEntity={currentAssembly}
          dependenciesWithModels$={modelStoreService.dependenciesWithModels$}
        />

        {ChangeVersionNameDialog()}
      </>
    )
  },
)

AssemblyClassifierEditorPage.displayName = 'AssemblyClassifierEditorPage'
