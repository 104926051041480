import * as React from 'react'
import { merge, Event } from 'effector'
import { useSnackbar } from 'notistack'
import { prop } from 'ramda'

import { ApiCallService } from '@gmini/api-call-service'
import * as smApi from '@gmini/sm-api-sdk'
import { ImportErrorNotify } from '@gmini/common'

import {
  migrateFailMessage,
  migrateSystemError,
} from '@gmini/common/lib/classifier-editor/Migration'

import { bimFileErrorMessageMap } from '@gmini/common/lib/classifier-editor/SelectDependencies/importErrorMessageMap'

import {
  classifierService,
  selectDependenciesClassifierService,
} from '../../services/userClassifierService'
import { apiErrMessageMap } from '../../services/apiErrMessageMap'
import { notificationService } from '../../services/notificationService'
import { userClassifierRepoService } from '../../services/userClassifierRepoServiceConnect'

const bimFileImportErrorEvent = notificationService.message
  .filter({ fn: smApi.NotificationEvent.Update.is })
  .map(prop('payload'))
  .filter({
    fn: payload =>
      smApi.BimFile.isBimFile(payload) ||
      smApi.BimFile.isModelRepositoryBimFile(payload),
  })
  .filter({
    fn: ({ status, errorCode }: smApi.BimFile) =>
      status === 'ImportError' && errorCode !== 'StructureError',
  }) as Event<smApi.BimFile>

const bimFileImportErrorMessage = bimFileErrorMessageMap(
  bimFileImportErrorEvent,
)

export const Notifications = React.memo<Record<string, unknown>>(() => {
  const { enqueueSnackbar } = useSnackbar()

  const openSnackbar = React.useCallback(
    (message: string) => {
      enqueueSnackbar(message, {
        variant: 'error',
      })
    },
    [enqueueSnackbar],
  )

  const openImportErrorSnackbar = React.useCallback(
    (data: { text: string; name: string; version: number } | null) => {
      if (!data) {
        return null
      }

      enqueueSnackbar(data, {
        variant: 'error',
        // eslint-disable-next-line react/display-name
        content: key => (
          <ImportErrorNotify
            key={key}
            text={data.text}
            name={data.name}
            version={data.version}
          />
        ),
      })
    },
    [enqueueSnackbar],
  )

  React.useEffect(() => {
    const failSubscription = anyFail.watch(openSnackbar)
    const iOTSSubscription = iOTSError.watch(({ errors, message }) => {
      openSnackbar(message)
    })

    const bimFileImportErrorSubscription = bimFileImportErrorMessage.watch(
      openImportErrorSnackbar,
    )

    const errorSubscription = error.watch(() => openSnackbar(unexpectedText))

    return () => {
      failSubscription.unsubscribe()
      iOTSSubscription.unsubscribe()
      errorSubscription.unsubscribe()
      bimFileImportErrorSubscription.unsubscribe()
    }
  }, [openImportErrorSnackbar, openSnackbar])

  return null
})

Notifications.displayName = 'Notifications'

const defaultMessage = (errorCode: string) =>
  `${unexpectedText}. [${errorCode}]`

const unexpectedText =
  'Непредвиденная ошибка. Пожалуйста, обратитесь к разработчикам'

const anyFail = merge([
  classifierService.anyRequestFail,
  selectDependenciesClassifierService.anyRequestFail,
  // TODO: быстрый костыль для выкатки (нужно перенести в общую логику обработки ошибок)
  smApi.AssemblyClassifier.runAssembly.fail.filterMap(({ error }) => {
    if (error.status === 'fail') {
      return {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        mappedMessage: (apiErrMessageMap as any)[error.data.errorCode],
        ...error.data,
      }
    }
  }),

  migrateFailMessage,
  migrateSystemError,
]).map(
  ({ errorCode, mappedMessage }) => mappedMessage || defaultMessage(errorCode),
)

const iOTSError = merge([
  userClassifierRepoService.IOTSError,
  classifierService.anyRequestIOTSError,
]).map(({ status: errorCode, errors }) => ({
  errors,
  message: defaultMessage(errorCode),
}))

const error = ApiCallService.fail.filterMap(({ params, name, error }) => {
  if (error.status === 'error') {
    return { params, name, error }
  }
})

export const notShownMessages = [
  'MISSING_REFRESH_TOKEN',
  'INVALID_REFRESH_TOKEN',
]
