import { tokenData$ } from '@gmini/common/lib/keycloakAuth'

import { FetchService } from '@gmini/utils'

import { ClassifierService } from '@gmini/common/lib/classifier-service'

import { removeAuthHeader, setAuthHeader } from '@gmini/common/lib/auth/auth'

import * as gStationApi from '@gmini/sm-api-sdk/lib/gStationConnect/gStationConnect'

import { refreshTokenFunc } from '@gmini/common/lib/keycloakAuth/auth/authData'

import * as smApi from '@gmini/sm-api-sdk'

import { gStationDocumentManagementUrl } from '../config'

const setManagement = new FetchService({
  baseUrl: '/api/sets',
  refreshTokenFunc,
})
const models = new FetchService({
  baseUrl: '/api/models',
  refreshTokenFunc,
})
const forge = new FetchService({
  baseUrl: '/api/forge-auth',
  refreshTokenFunc,
})
const gStationModelNavigation = new FetchService({
  baseUrl: '/api/g-station-model-navigation',
  refreshTokenFunc,
})

const gStation = new FetchService({
  baseUrl: gStationDocumentManagementUrl,
})

export const authApi = new FetchService({ baseUrl: '/api/auth/v1' })

tokenData$.watch(tokenData => {
  if (tokenData?.accessToken) {
    setAuthHeader(
      [
        setManagement,
        models,
        forge,
        authApi,
        gStationModelNavigation,
        gStation,
      ],
      tokenData.accessToken,
    )
  } else {
    removeAuthHeader([
      setManagement,
      models,
      forge,
      authApi,
      gStationModelNavigation,
      gStation,
    ])
  }
})

smApi.Auth.User.fetch.use(() =>
  authApi.get(`/auth/current-user`).then(smApi.Auth.User.fetch.toPromise),
)
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function classifierServiceConnect(classifierService: ClassifierService) {
  smApi.setManagementConnect(setManagement)
  smApi.modelRepoConnect(models)
  smApi.navigationModelRepoConnect(gStationModelNavigation)
  gStationApi.gStationConnect(gStation)
  return classifierService
}
