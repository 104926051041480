import { createSelectSourcePanel } from '@gmini/common/lib/components/FilterPanel/components/SelectSourcePanel/createSelectSourcePanel'

import { classifierService } from '../../../services/userClassifierService'

import { currentUserClassifier$ } from '../../CurrentUserClassifier'

import { filterPanelService } from './filterPanelService'
import { dependencyCheckedModel } from './checkedModel'

export const {
  SelectSourcePanel,
  selectedSourceNodes$,
} = createSelectSourcePanel({
  checkedModel: dependencyCheckedModel,
  nodes$: classifierService.nodes$,
  sources: filterPanelService.sources,
  currentEntity$: currentUserClassifier$,
})
