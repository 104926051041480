import { combine } from 'effector'

import { isNotEmpty } from '@gmini/utils'

import { selectedModels } from '@gmini/common/lib/classifier-editor'

import { selectedElements$ } from '@gmini/common/lib/forge-viewer'
export const selectedForgeElements$ = combine(
  {
    selectedModels: selectedModels.models$,
    selectedElements: selectedElements$,
  },
  ({ selectedModels, selectedElements }) =>
    selectedElements
      ? Object.entries(selectedElements)
          .map(([viewerId, externalIds]) => {
            const model = selectedModels?.find(
              model => model.viewerId === viewerId,
            )

            return model
              ? {
                  modelId: model.modelId,
                  modelVersion: model.modelVersion,
                  elementForgeExternalIds: externalIds,
                }
              : null
          })
          .filter(isNotEmpty)
      : [],
)
