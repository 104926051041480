import { memo, useEffect } from 'react'
import { useStore } from 'effector-react'
import { Redirect, useParams } from 'react-router-dom'

import { ReadonlyModeProvider } from '@gmini/common'

import * as smApi from '@gmini/sm-api-sdk'

import {
  CurrentUserClassifier,
  currentUserClassifier$,
} from '../CurrentUserClassifier'

import {
  checkVersion,
  oldVersionMode$,
} from '../../services/userClassifierService'

import { UserClassifierEditorPage } from './UserClassifierEditorPage'

export const EditorByVersionWrap = memo(() => {
  const { id, version } = useParams<{ id: string; version: string }>()

  const currentClassifier = useStore(currentUserClassifier$)
  const readonlyMode = !!currentClassifier?.readOnly
  const oldVersionMode = useStore(oldVersionMode$)
  const versionReqPending = useStore(checkVersion.pending)

  useEffect(() => {
    smApi.Project.fetchList.defaultContext.submit()
  }, [])

  useEffect(() => {
    if (!oldVersionMode && !versionReqPending) {
      checkVersion({
        id: +id,
        version: +version,
      })
    }

    if (oldVersionMode?.active) {
      smApi.UserClassifier.fetch.defaultContext.submit({
        id: +id,
        version: +version,
      })
    }
  }, [versionReqPending, oldVersionMode, id, version])

  if (versionReqPending) {
    return null
  }

  if (oldVersionMode && !oldVersionMode?.active) {
    return <Redirect to={`/user-classifiers/${id}`} />
  }

  if (!currentClassifier) {
    return <CurrentUserClassifier fetch />
  }

  return (
    <>
      <CurrentUserClassifier fetch />
      <ReadonlyModeProvider
        value={{
          enabled: readonlyMode,
          description: readonlyMode
            ? 'Классификатор находится в режиме просмотра'
            : '',
        }}
      >
        <UserClassifierEditorPage
          currentClassifier={currentClassifier}
          isCurrentVersion={!oldVersionMode?.active}
        />
      </ReadonlyModeProvider>
    </>
  )
})
EditorByVersionWrap.displayName = 'EditorByVersionWrap'
