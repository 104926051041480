import { createEffect, createStore } from 'effector'

import * as smApi from '@gmini/sm-api-sdk'

export const checkVersionReqAssembly = smApi.AssemblyClassifier.getMostRecentSilent.createContext(
  'checkVersion',
)

export const checkVersionAssembly = createEffect({
  handler: async ({ id, version }: { id: number; version: number }) => {
    const actual = await checkVersionReqAssembly({ id })
    const active = actual.version < version ? false : actual.version !== version
    return { active }
  },
})

export const oldVersionModeAssembly$ = createStore<{ active: boolean } | null>(
  null,
)
  .on(checkVersionAssembly.doneData, (_, value) => value)
  .on(checkVersionAssembly.failData, () => ({ active: false }))
  .on(smApi.AssemblyClassifier.getMostRecent.doneData, () => null)
