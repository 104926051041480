import { useEffect } from 'react'
import { useStore } from 'effector-react'
import { Redirect, useParams } from 'react-router-dom'

import { ReadonlyModeProvider } from '@gmini/common'

import * as smApi from '@gmini/sm-api-sdk'

import {
  checkVersionAssembly,
  oldVersionModeAssembly$,
} from '../../services/userClassifierService'

import {
  currentAssembly$,
  CurrentAssemblyClassifier,
} from '../CurrentAssembly/CurrentAssembly'

import { AssemblyClassifierEditorPage } from './AssemblyClassifierEditorPage'

export const EditorByVersionAssemblyWrap = () => {
  const { id, version } = useParams<{ id: string; version: string }>()

  const currentAssembly = useStore(currentAssembly$)
  const readonlyMode = !!currentAssembly?.readOnly
  const oldVersionMode = useStore(oldVersionModeAssembly$)
  const versionReqPending = useStore(checkVersionAssembly.pending)

  useEffect(() => {
    smApi.Project.fetchList.defaultContext.submit()
  }, [])

  useEffect(() => {
    if (!oldVersionMode && !versionReqPending) {
      checkVersionAssembly({
        id: +id,
        version: +version,
      })
    }

    if (oldVersionMode?.active) {
      smApi.AssemblyClassifier.fetch.defaultContext.submit({
        id: +id,
        version: +version,
      })
    }
  }, [versionReqPending, oldVersionMode, id, version])

  if (versionReqPending) {
    return null
  }

  if (oldVersionMode && !oldVersionMode?.active) {
    return <Redirect to={`/assembly-classifier/${id}`} />
  }

  if (!currentAssembly) {
    return <CurrentAssemblyClassifier fetch />
  }

  return (
    <>
      <CurrentAssemblyClassifier fetch />
      <ReadonlyModeProvider
        value={{
          enabled: readonlyMode,
          description: readonlyMode
            ? 'Сборка находится в режиме просмотра'
            : '',
        }}
      >
        <AssemblyClassifierEditorPage
          currentAssembly={currentAssembly}
          isCurrentVersion={!oldVersionMode?.active}
        />
      </ReadonlyModeProvider>
    </>
  )
}
