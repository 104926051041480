import { classifierService } from '../../../services/userClassifierService'

import { currentAssembly$ } from '../../CurrentAssembly/CurrentAssembly'

import { createAssemblyTreeModel } from './treeModel'

export const assemblyTreeModel = createAssemblyTreeModel({
  currentAssembly$,
  nodes$: classifierService.nodes$,
})
